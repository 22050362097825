var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{staticClass:"dialog-create",attrs:{"max-width":"85%"},model:{value:(_vm.dialogCriarCupom),callback:function ($$v) {_vm.dialogCriarCupom=$$v},expression:"dialogCriarCupom"}},[_c('parceria-cupom-create-component',{attrs:{"propParceriaId":parseInt(_vm.propParceriaId, 0)},on:{"on-cancel":function($event){return _vm.onCancel()}}})],1),_c('v-dialog',{attrs:{"max-width":"1400px"},model:{value:(_vm.dialogFiltrosAdicionais),callback:function ($$v) {_vm.dialogFiltrosAdicionais=$$v},expression:"dialogFiltrosAdicionais"}},[_c('v-card',{staticClass:"dialog-filters"},[_c('v-card-title',{staticClass:"fira-semi-bold ml-5 pt-5 pb-0",staticStyle:{"font-size":"28px"}},[_vm._v("Mais filtros")]),_c('v-card-text',[_c('v-row',{staticClass:"pa-4"},[(_vm.totals.por_status)?_c('v-col',{staticStyle:{"overflow-y":"auto"},attrs:{"cols":"4"}},[_c('GenericListTotalCardComponent',{staticClass:"table-ticket pa-4",staticStyle:{"height":"100%"},attrs:{"prop-title":"Por status","prop-items":_vm.totals.por_status,"prop-fixed-header":true,"prop-height":'350'},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('span',[_vm._v(" "+_vm._s(_vm._.find( _vm.parceriaCupomStatusOptions(), function (i) { return i.id == item.id; } ).name)+" ")]):_vm._e()]}}],null,true)})],1):_vm._e(),(_vm.totals.por_parceria && !_vm.propParceriaId)?_c('v-col',{attrs:{"cols":"4"}},[_c('GenericListTotalCardComponent',{staticClass:"table-ticket pa-4",staticStyle:{"height":"100%"},attrs:{"prop-title":"Por parceria","prop-items":_vm.totals.por_parceria,"prop-fixed-header":true,"prop-height":'350'}})],1):_vm._e(),(_vm.totals.por_tipo)?_c('v-col',{attrs:{"cols":"4"}},[_c('GenericListTotalCardComponent',{staticClass:"table-ticket pa-4",staticStyle:{"height":"100%"},attrs:{"prop-title":"Por tipo","prop-items":_vm.totals.por_tipo,"prop-fixed-header":true,"prop-height":'350'},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [(item.label)?_c('span',[_vm._v(" "+_vm._s(_vm._.find(_vm.parceriaCupomTipoOptions(), function (i) { return i.id == item.id; }) .name)+" ")]):_c('span',[_vm._v(" Nenhum ")])]}}],null,true)})],1):_vm._e()],1),_c('v-row',{staticClass:"pa-4"},[(_vm.totals.por_campanha)?_c('v-col',[_c('GenericListTotalCardComponent',{staticClass:"table-ticket pa-4",staticStyle:{"height":"100%"},attrs:{"prop-title":"Por campanha","prop-items":_vm.totals.por_campanha,"prop-fixed-header":true,"prop-height":'350'},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [(item.label)?_c('span',[_vm._v(" "+_vm._s(item.label)+" ")]):_c('span',[_vm._v(" Nenhum ")])]}}],null,true)})],1):_vm._e(),(_vm.totals.por_cda)?_c('v-col',[_c('GenericListTotalCardComponent',{staticClass:"table-ticket pa-4",staticStyle:{"height":"100%"},attrs:{"prop-title":("Por " + (_vm.getNomeCDA())),"prop-items":_vm.totals.por_cda,"prop-fixed-header":true,"prop-height":'350'},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [(item.label)?_c('span',[_vm._v(" "+_vm._s(item.label)+" ")]):_c('span',[_vm._v(" Nenhum ")])]}}],null,true)})],1):_vm._e()],1)],1),_c('v-card-actions',{staticStyle:{"padding":"20px"}},[_c('v-btn',{attrs:{"rounded":"","outlined":"","color":"#6400AC"},on:{"click":_vm.selecionarTudo}},[_vm._v("Selecionar Tudo")]),_c('v-btn',{attrs:{"rounded":"","outlined":"","color":"#6400AC"},on:{"click":_vm.limparSelecionados}},[_vm._v("Limpar Selecionados")]),_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","outlined":"","color":"#6400AC"},on:{"click":function($event){_vm.dialogFiltrosAdicionais = false}}},[_vm._v("Fechar")])],1)],1)],1),_c('v-card',{staticClass:"pa-4 card-digital",class:_vm.isMobile ? 'mt-4' : 'ma-4'},[_c('v-card-title',{staticClass:"fira-semi-bold title-parcerias"},[_vm._v(" Cupons ")]),_c('v-row',{staticClass:"pa-4"},[_c('v-col',{attrs:{"cols":_vm.isMobile ? 'auto' : 2}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","label":"Período (Data)","append-icon":"keyboard_arrow_down","outlined":"","rounded":"","dense":"","hide-details":"","value":_vm.formattedDate}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","range":"","max":new Date().toISOString().substr(0, 10)},model:{value:(_vm.selectPeriodDate),callback:function ($$v) {_vm.selectPeriodDate=$$v},expression:"selectPeriodDate"}})],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('ButtonComponent',{attrs:{"propOutlined":"","propTitle":"Exibir mais filtros","propClick":function () {
            _vm.dialogFiltrosAdicionais = true;
          }}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('ButtonComponent',{attrs:{"propDisabled":_vm.isClearFiltersDisabled,"propTitle":"Limpar todos os filtros","propClick":_vm.clearFilters}})],1),(_vm.isMobile)?_c('v-spacer'):_vm._e(),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"dense":"","items":_vm.headers,"item-text":"text","item-value":"value","multiple":"","label":"Editar colunas","hide-details":"","outlined":"","rounded":"","loading":_vm.loadingSave,"menu-props":{ offsetY: true }},on:{"input":_vm.onChangeSelectAllCheckbox},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [(_vm.selectCheckbox.length === 1)?_c('span',{staticClass:"purplemain--text"},[_vm._v(" "+_vm._s(item.text)+" ")]):(index === 0)?_c('span',{staticClass:"purplemain--text"},[_vm._v(" "+_vm._s(_vm.selectCheckbox.length)+" filtros selec. ")]):_vm._e()]}}]),model:{value:(_vm.selectCheckbox),callback:function ($$v) {_vm.selectCheckbox=$$v},expression:"selectCheckbox"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.checkEscolaColaborador)?_c('ButtonComponent',{attrs:{"propTitle":"Selecionar todos os cupons","propClick":_vm.selectAll}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.allowCreate && !_vm.propModoMigrar)?_c('ButtonComponent',{attrs:{"propTitle":"Cadastrar cupom","propClick":_vm.criarCupom,"propColor":"#6400AC"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-card-text',{class:_vm.isMobile ? 'pa-2' : ''},[_c('GenericListItemsCardUpdatedComponent',{ref:"tabela",attrs:{"propExpandable":true,"funcExpandable":_vm.showParceriaCupomHistorico,"propPermiteImpimirTabela":true},scopedSlots:_vm._u([{key:"item.selected",fn:function(ref){
          var item = ref.item;
return [(item.parceria_nicho_id !== 8 && (!item.cda_id || _vm.propModoMigrar))?_c('v-checkbox',{attrs:{"dense":"","hide-details":""},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}}):_vm._e()]}},{key:"item.parceria_nome",fn:function(ref){
          var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.parceria_nome)),"propObjectId":item.parceria_id,"propToolName":"Parcerias"}})]}},{key:"item.nome",fn:function(ref){
          var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.nome)),"propObjectId":item.id,"propToolName":"Cupons"}})]}},{key:"item.created_at",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"item.cda_nome",fn:function(ref){
          var item = ref.item;
return [(item.cda_nome)?_c('span',[_vm._v(" "+_vm._s(item.cda_nome)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.desvincularCDA(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" assignment_return ")])]}}],null,true)},[_c('span',[_vm._v("Desvincular "+_vm._s(_vm.getNomeCDA()))])])],1):_vm._e()]}},{key:"item.interesse_id",fn:function(ref){
          var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(_vm._.find(_vm.parceriaCupomTipoOptions(), function (i) { return i.id == value; }) .name)+" ")]):_c('span',[_vm._v(" Nenhum ")])]}},{key:"item.status_id",fn:function(ref){
          var item = ref.item;
return [(!!item.entidade_obj)?_c('div',_vm._l((item.entidade_obj),function(entidade_obj){return _c('div',[(entidade_obj.aluno_id)?_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.openAluno(entidade_obj.aluno_id)}}},[_vm._v(" aluno ")]):(entidade_obj.prospex_id)?_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.openProspex(entidade_obj.prospex_id)}}},[_vm._v(" prospex ")]):_vm._e()],1)}),0):_c('v-chip',{class:_vm.statusClass(item.status_id),attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.getStatusPorData(item))+" ")])]}},{key:"item.ultimo_contato",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"item.cda",fn:function(ref){
          var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(value.full_name)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(_vm.allowDelete && (!item.prospex_id || !item.aluno_id))?_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v("mdi-delete")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"ma-1 pa-3",staticStyle:{"border":"1px solid gray","border-radius":"16px"}},[_c('v-data-table',{attrs:{"items":_vm.historicoItems,"headers":_vm.headersObservacoes,"loading":_vm.historicoLoading,"dense":"","footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"item.status_id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.find( _vm.parceriaCupomStatusOptions(), function (i) { return i.id == item.status_id; } ).name)+" ")]}}],null,true)})],1)])]}}],null,true)})],1)],1)],1),_c('v-card-title',{staticClass:"fira-semi-bold title-parcerias"},[_vm._v(" Distribuir cupons para "+_vm._s(_vm.getNomeCDA())+" ")]),_c('v-row',[(_vm.userActionDistribuirCuponsEntreCdasAllow)?_c('v-col',{staticStyle:{"margin-left":"20px"},attrs:{"cols":"5"}},[_c('v-autocomplete',{attrs:{"label":("Selecione um " + (this.getNomeCDA())),"items":_vm.cdaOptions,"item-text":"full_name","item-value":"id","return-object":""},model:{value:(_vm.movidoParaCda),callback:function ($$v) {_vm.movidoParaCda=$$v},expression:"movidoParaCda"}})],1):_vm._e(),(_vm.userActionDistribuirCuponsEntreCdasAllow)?_c('v-col',{attrs:{"cols":"auto"}},[_c('ButtonComponent',{attrs:{"propTitle":'Mover ' + this.getNomeCDA(),"propClick":_vm.moverPara,"propDisabled":!(_vm.movidoParaCda.id >= 0) || _vm.cdaOptions.length <= 1}})],1):_vm._e(),(_vm.userActionExcluirCuponsEmLoteAllow)?_c('v-col',{attrs:{"cols":"auto"}},[_c('ButtonComponent',{attrs:{"propTitle":"Excluir Selecionados","propClick":_vm.excluirLote,"propDisabled":_vm.cdaOptions.length <= 1,"propColor":"#E6EDF4","propStyle":"color: #313943"}})],1):_vm._e()],1),(_vm.userActionDistribuirCuponsEntreCdasAllow)?_c('span',{staticStyle:{"margin-left":"20px","color":"#6400AC"}},[_vm._v(" Total de cupons selecionados: "+_vm._s(_vm.totalSelecionadoCount > 0 ? _vm.totalSelecionadoCount : _vm.totalSelecionado)+" ")]):_vm._e(),(_vm.userActionDistribuirCuponsEntreCdasAllow)?_c('v-row',[_c('v-col',[(_vm.cdaOptions.length <= 1)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("Para distribuir cupons você precisa cadastrar ao menos um "+_vm._s(_vm.getNomeCDA()))]):_vm._e()])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }