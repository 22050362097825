import { __decorate } from "tslib";
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { cdaStore, parceriaCupomHistoricoStore, parceriaCupomStore, parceriaStore, userActionStore, userToolStore, analyticsStore, filtroPorTabelaStore } from '@/store';
import SaveButton from '@/components/SaveButton.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import LogShowComponent from '@/components/LogShowComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import _ from 'lodash';
import { isToday, isSameDay, parseISO, endOfToday } from 'date-fns';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import ImprimirTabelaComponent from '@/components/ImprimirTabelaComponent.vue';
import ParceriaCupomCreateComponent from '@/components/ParceriaCupomCreateComponent.vue';
import GenericListItemsCardUpdatedComponent from '@/components/GenericListItemsCardUpdatedComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { ValidationObserver, ValidationProvider, Validator } from 'vee-validate';
import { newParceria } from '@/interfaces/parceria';
Validator.extend('required', {
    getMessage: field => `${field} deve ser preenchido!`,
    validate: async (value) => !!value
});
let ParceriaCupomCrudComponent = class ParceriaCupomCrudComponent extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.tabelaRef = null;
        this.parceria = {
            data_inicio: '',
            data_termino: '',
            nome: 'Todos',
            descricao: '',
            company_id: 0,
            id: 0
        };
        this.movidoParaCda = { id: -1 };
        this.search = '';
        this.dialogCriarCupom = false;
        this.historicoItems = [];
        this.historicoLoading = false;
        this.totalSelecionadoCount = 0;
        this.headers = [
            { text: '', value: 'selected', width: '20px', show: true },
            {
                text: 'Criado em',
                value: 'created_at',
                show: true,
                table: 'parceria_cupom',
                type: 'timestamp'
            },
            {
                text: 'Nome',
                value: 'nome',
                show: true,
                table: 'parceria_cupom',
                type: 'str'
            },
            {
                text: 'E-mail',
                value: 'email',
                show: true,
                table: 'parceria_cupom',
                type: 'str'
            },
            {
                text: 'Idade',
                value: 'idade',
                show: true,
                table: 'parceria_cupom',
                type: 'int'
            },
            {
                text: 'Telefone',
                value: 'telefone',
                show: true,
                table: 'parceria_cupom',
                type: 'str'
            },
            // @ts-ignore
            { text: `${this.getNomeCDA()} responsável`, value: 'cda_nome', show: true },
            { text: 'Status atual', value: 'status_id', show: true },
            { text: 'Ultimo contato', value: 'ultimo_contato', show: true },
            { text: 'Tipo', value: 'interesse_id', show: true },
            {
                text: 'Campanha',
                value: 'campaign',
                show: true,
                table: 'parceria_cupom',
                type: 'str'
            },
            {
                text: 'Indicado por',
                value: 'indicador_nome',
                show: true,
                table: 'parceria_cupom',
                type: '',
            },
            { text: '', value: 'actions', show: true },
            // { text: '', value: '', show: true },
            { text: '', value: 'data-table-expand', show: true }
        ];
        this.headersObservacoes = [
            { text: 'Data do contato', value: 'created_at' },
            { text: 'Status', value: 'status_id' },
            { text: 'Observações', value: 'observacao' }
        ];
        this.statusHeaders = [
            { text: 'Status', value: 'status_id' },
            { text: 'Quantidade', value: 'quantidade' }
        ];
        this.interesseHeaders = [
            { text: 'Interesse', value: 'interesse_id' },
            { text: 'Quantidade', value: 'quantidade' }
        ];
        this.statusItems = [];
        this.statusSelected = [];
        this.interesseItems = [];
        this.interesseSelected = [];
        this.parceriaCuponsItems = [];
        this.menu = false;
        this.selectPeriodDate = [];
        this.dialogFiltrosAdicionais = false;
        this.selectCheckbox = null;
        this.loadingSave = false;
        this.filtros = {
            desistiu: false,
            semInteresse: false
            // Adicione mais filtros conforme necessário
        };
    }
    get checkEscolaColaborador() {
        return this.$route.path.includes('/EscolaColaboradores/colaboradores/');
    }
    get totals() {
        return analyticsStore.totals;
    }
    get headersFiltered() {
        return _.filter(this.headers, item => item.show);
    }
    get totalSelecionado() {
        const cupons = analyticsStore.items.filter((item) => {
            return item.selected;
        });
        return cupons.length;
    }
    get parceriasOptions() {
        const obj = { ...newParceria(), id: 0 };
        obj.nome = 'Todos';
        const options = [...parceriaStore.parcerias];
        options.unshift(obj);
        return options;
    }
    get userActionDistribuirCuponsEntreCdasAllow() {
        return userActionStore.distribuirCuponsEntreCdasAllow;
    }
    get userActionExcluirCuponsEmLoteAllow() {
        return userActionStore.excluirCuponsEmLote;
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Cupons) {
            return userToolStore.userToolsIndexed.Cupons[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Cupons) {
            return userToolStore.userToolsIndexed.Cupons[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Cupons) {
            return userToolStore.userToolsIndexed.Cupons[0].allow_delete;
        }
    }
    get formattedDate() {
        if (this.selectPeriodDate.length === 2) {
            const [start, end] = this.selectPeriodDate;
            return `${this.formatDate(start)} - ${this.formatDate(end)}`;
        }
        else if (this.selectPeriodDate.length === 1) {
            return `${this.formatDate(this.selectPeriodDate[0])}`;
        }
        return '';
    }
    clearFilters() {
        this.selectPeriodDate = [];
        this.search = '';
        this.$forceUpdate();
    }
    selectAll() {
        analyticsStore.items.forEach((item) => {
            if (!item.cda_id || this.propModoMigrar) {
                item.selected = true;
            }
        });
        this.totalSelecionadoCount = analyticsStore.items.filter((item) => item.selected).length;
        this.$forceUpdate();
    }
    get isClearFiltersDisabled() {
        return this.selectPeriodDate.length === 0 && this.search === '';
    }
    aplicarFiltros() {
        this.dialogFiltrosAdicionais = false;
    }
    limparFiltros() {
        this.filtros = {
            desistiu: false,
            semInteresse: false
        };
    }
    async selecionarTudo() {
        for (const key in this.totals) {
            if (this.totals[key].items) {
                this.totals[key].items.forEach(item => {
                    item.selected = true;
                });
            }
        }
    }
    async limparSelecionados() {
        for (const key in this.totals) {
            if (this.totals[key].items) {
                this.totals[key].items.forEach(item => {
                    item.selected = false;
                });
            }
        }
    }
    statusClass(value) {
        switch (value) {
            case 1:
                return 'status-atendeu';
            case 2:
                return 'status-nao-atendeu';
            case 3:
                return 'status-agendado';
            case 4:
                return 'status-reagendado';
            case 5:
                return 'status-confirmou';
            case 6:
                return 'status-cancelado-desistiu';
            case 7:
                return 'status-sem-interesse';
            case 9:
                return 'status-confirmou-visita';
            case 11:
                return 'status-ligar-outro-momento';
            case 14:
                return 'status-caixa-postal';
            case 15:
                return 'status-telefone-nao-existente';
            default:
                return '';
        }
    }
    async onChangeSelectAllCheckbox(val) {
        if (this.loadingSave) {
            return;
        }
        this.loadingSave = true;
        await filtroPorTabelaStore.filtroPorTabelaBuscaFiltroSessao('list_coupons');
        this.headers.forEach(header => {
            header.show = val.includes(header.value);
        });
        const items = this.headers.filter(item => item.show);
        await filtroPorTabelaStore.filtroPorTabelaCreate({
            items,
            table_name: 'list_coupons'
        });
        this.loadingSave = false;
    }
    formatPhoneNumber(phone) {
        if (!phone)
            return '';
        // Remove tudo que não for número
        const cleaned = ('' + phone).replace(/\D/g, '');
        // Adiciona a máscara para telefone
        const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phone;
    }
    async refazerBusca() {
        this.loading = true;
        await this.buscar();
        this.loading = false;
    }
    get cdaOptions() {
        const cdas = cdaStore.cdasAtivos;
        // @ts-ignore
        cdas.unshift({
            id: 0,
            // @ts-ignore
            full_name: this.getNomeCDA() + 's ALEATÓRIOS'
        });
        return cdas;
    }
    getStatusPorData(item) {
        // @ts-ignore
        const status = _.find(this.parceriaCupomStatusOptions(), i => i.id == item.status_id).name;
        if (!item.ultimo_agendamento) {
            return status;
        }
        const dataAgendamento = parseISO(item.ultimo_agendamento);
        if (isToday(dataAgendamento)) {
            if (item.prospex_created_at &&
                isSameDay(dataAgendamento, parseISO(item.prospex_created_at))) {
                return 'Compareceu';
            }
            return 'Agendado para hoje';
        }
        if (item.status_id !== 7 && endOfToday() > dataAgendamento) {
            return 'Não compareceu';
        }
        return status;
    }
    async showParceriaCupomHistorico(item) {
        this.historicoItems = [];
        this.historicoLoading = true;
        this.historicoItems = await parceriaCupomHistoricoStore.getParceriaCupomHistoricoByParceriaCupomId(item.item.id);
        this.historicoLoading = false;
    }
    showFiltroPorTabela() {
        // @ts-ignore
        const form = this.$refs.filtroPorTabela;
        form.show('aluno');
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-cupons-edit', params: { id } });
    }
    async criarCupom() {
        this.dialogCriarCupom = true;
    }
    async onCancel() {
        this.loading = true;
        this.dialogCriarCupom = false;
        await this.buscar();
        this.loading = false;
    }
    openAluno(alunoId) {
        const rota = this.$router.resolve({
            name: 'main-alunos-edit',
            params: { id: alunoId }
        });
        window.open(rota.href, '_blank');
    }
    openProspex(prospexId) {
        const rota = this.$router.resolve({
            name: 'main-prospexs-edit',
            params: { id: prospexId }
        });
        window.open(rota.href, '_blank');
    }
    desvincularCDA(parceriaCupomId) {
        // @ts-ignore
        const tipo = this.getNomeCDA();
        this.$swal({
            title: `Confirma o desvinculo com o ${tipo}?`,
            text: `Caso o cupom seja desvinculado ele ficará novamente disponível para ser vinculado a outro ${tipo}`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await parceriaCupomStore.desvincularParceriaCupomCda(parceriaCupomId);
                const item = _.find(analyticsStore.items, i => i.id == parceriaCupomId);
                item.cda_id = null;
                item.cda_nome = null;
            }
        });
    }
    async moverPara() {
        this.$swal({
            title: 'Confirma a distribuição dos cupons?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const cupons = analyticsStore.items.filter((item) => {
                    if (item.selected) {
                        item.selected = false;
                        return item;
                    }
                });
                if (cupons.length === 0) {
                    this.$swal({
                        text: 'Para distribuir você deve selecionar ao menos um cupom!',
                        icon: 'error'
                    });
                    return 1;
                }
                const payload = {
                    cupons,
                    cda_id: this.movidoParaCda
                };
                const response = await cdaStore.distribuirCupons(payload);
                if (response) {
                    this.$swal({
                        title: 'Distribuido!',
                        text: 'O(s) cupom(s) foi distribuido com sucesso.',
                        icon: 'success'
                    });
                    await this.buscar();
                }
            }
        });
    }
    async excluirLote() {
        this.$swal({
            title: 'Você confirma a exclusão dos cupons?',
            text: 'Atenção! Esta operação não pode ser desfeita!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.$swal({
                    title: 'Deletado!',
                    text: 'O cupom(s) foi deletado com sucesso.',
                    icon: 'success'
                });
                const cupons = analyticsStore.items.filter((item) => {
                    if (item.selected) {
                        item.selected = false;
                        return item;
                    }
                });
                if (cupons.length === 0) {
                    this.$swal({
                        text: 'Para distribuir você deve selecionar ao menos um cupom!',
                        icon: 'error'
                    });
                    return 1;
                }
                const payload = {
                    cupons
                };
                await cdaStore.excluirCuponsLote(payload);
                await this.buscar();
            }
        });
    }
    async deleteItem(id) {
        this.$swal({
            title: 'Confirma a exclusão do cupom?',
            text: 'Após exclusão o cupom não pode ser recuperado!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Voltar',
            confirmButtonText: 'Sim, eu confirmo!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                await parceriaCupomStore.deleteParceriaCupom(id);
                await this.buscar();
                this.loading = false;
            }
        });
    }
    async created() {
        this.$nextTick(() => {
            // @ts-ignore
            this.tabelaRef = this.$refs.tabela;
        });
    }
    async doSearchItems(queryIn) {
        return await parceriaCupomStore.getParceriaCuponsQuery(queryIn);
    }
    async buscar() {
        const filters = {};
        if (this.propParceriaId) {
            filters['parceria_cupom.parceria_id'] = {
                type: 'int',
                value: [this.propParceriaId]
            };
        }
        if (this.propCdaId) {
            filters['parceria_cupom.cda_id'] = {
                type: 'int',
                value: [this.propCdaId]
            };
        }
        if (this.selectPeriodDate && this.selectPeriodDate.length === 2) {
            const [startDate, endDate] = this.selectPeriodDate;
            filters['parceria_cupom.created_at'] = {
                type: 'date_range',
                value: [startDate, endDate]
            };
        }
        analyticsStore.setDefaultFilters(filters);
        await this.doSearchTotals();
        await analyticsStore.executeSearchItems();
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsParceriaCupons({
            parceriaId: this.propParceriaId,
            cdaId: this.propCdaId
        });
    }
    async mounted() {
        if (!this.propParceriaId) {
            const column = { text: 'Parceria', value: 'parceria_nome', show: true };
            this.headers.splice(1, 0, column);
        }
        this.loading = true;
        this.selectCheckbox = this.headers.map(item => item.value);
        // await this.buscarParceriaCuponsItems();
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(parceriaCupomStore.getParceriaCuponsQuery);
        analyticsStore.setHeaders(this.headers);
        this.buscar();
        await cdaStore.getCdas();
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Number, default: null })
], ParceriaCupomCrudComponent.prototype, "propParceriaId", void 0);
__decorate([
    Prop({ type: Number, default: null })
], ParceriaCupomCrudComponent.prototype, "propCdaId", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ParceriaCupomCrudComponent.prototype, "propModoMigrar", void 0);
__decorate([
    Watch('selectPeriodDate')
], ParceriaCupomCrudComponent.prototype, "refazerBusca", null);
ParceriaCupomCrudComponent = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            ClickableCard,
            DateFieldComponent,
            ImprimirTabelaComponent,
            LogShowComponent,
            OpenNewTabComponent,
            ParceriaCupomCreateComponent,
            SaveButton,
            SearchEntidadeComponent,
            GenericListItemsCardUpdatedComponent,
            GenericListTotalCardComponent,
            PeriodSelectorComponent,
            ButtonComponent
        }
    })
], ParceriaCupomCrudComponent);
export default ParceriaCupomCrudComponent;
